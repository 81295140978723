import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

export const routes = {
  root: '/',
  swap: '/',
  admin: '/admin',
  iframe: '/iframe',
  volume: '/volume',
} as const;

export const router = createBrowserRouter([
  {
    path: routes.root,
    Component: lazy(() => import('@/layouts/default-layout')),
    children: [
      {
        path: routes.swap,
        Component: lazy(() => import('@/pages/swap')),
      },
      {
        path: routes.admin,
        Component: lazy(() => import('@/pages/admin')),
      },
      {
        path: routes.volume,
        Component: lazy(() => import('@/pages/volume')),
      },
      {
        path: '*',
        Component: lazy(() => import('@/pages/not-found')),
      },
    ],
  },
  {
    path: routes.root,
    Component: lazy(() => import('@/layouts/iframe-layout')),
    children: [
      {
        path: routes.iframe,
        Component: lazy(() => import('@/pages/iframe')),
      },
    ],
  },
]);
